import { round } from "lodash";

type ConversionType = 'length' | 'weight';
type LengthConversionUnit = 'centimeter' | 'inch';
type WeightConversionUnit = 'kilogram' | 'pound';
type ConversionUnit = LengthConversionUnit | WeightConversionUnit;

/**
 * Please verify the conversion below before implementing 

    // Metric to imperial
    meter: {
      inch: 39.3701,
      foot: 3.28084,
      yard: 1.09361,
      mile: 0.000621371
    },
    centimeter: {
      inch: 0.393701,
      foot: 0.0328084,
      yard: 0.0109361,
      mile: 0.00000621371
    },
    kilometer: {
      inch: 39370.1,
      foot: 3280.84,
      yard: 1093.61,
      mile: 0.621371
    },

    // Imperial to metric
    inch: {
      meter: 0.0254,
      centimeter: 2.54,
      kilometer: 0.0000254
    },
    foot: {
      meter: 0.3048,
      centimeter: 30.48,
      kilometer: 0.0003048
    },
    yard: {
      meter: 0.9144,
      centimeter: 91.44,
      kilometer: 0.0009144
    },
    mile: {
      meter: 1609.344,
      centimeter: 160934.4,
      kilometer: 1.609344
    }

	// Mass/Weight
    kilogram: {
      pound: 2.20462,
      ounce: 35.274,
      ton: 0.00110231
    },
    gram: {
      pound: 0.00220462,
      ounce: 0.035274,
      ton: 0.00000110231
    },
    pound: {
      kilogram: 0.453592,
      gram: 453.592
    },
    ounce: {
      kilogram: 0.0283495,
      gram: 28.3495
    },
    // ... other mass/weight units

 */
const CONVERSION_FACTORS: Record<ConversionType, Partial<Record<ConversionUnit, Partial<Record<ConversionUnit, number>>>>> = {
	length: {
		// Metric to imperial
		centimeter: {
			inch: 0.393701
		},

		// Imperial to metric
		inch: {
			centimeter: 2.54
		}
	},
	weight: {
		// Metric to imperial
		kilogram: {
			pound: 2.20462
		},

		// Imperial to metric
		pound: {
			kilogram: 0.453592
		}
	}
};

const configUnitConversion = <T extends ConversionUnit>(type: ConversionType) => {
	const conversionFactorsForType = CONVERSION_FACTORS[type]
	if (!conversionFactorsForType) {
		throw new Error(`Unsupported unit conversion tyoe: ${type}`);
	}

	return (value: number, fromUnit: T, toUnit: T, precision = 0): number => {
		const conversionFactor: number | undefined = conversionFactorsForType[fromUnit]?.[toUnit];
		if (!conversionFactor) {
			throw new Error(`Unsupported units: ${fromUnit} to ${toUnit}`);
		}
		return round(value * conversionFactor, precision);
	}
}

/**
 * Converts a length value from one unit to another.
 *
 * @param value The value to be converted.
 * @param fromUnit The unit of the input value.
 * @param toUnit The desired unit for the output value.
 * @param precision The precision to round to. Defaults 0
 * @returns The converted value.
 * @throws An error if the units are not supported.
 * 
 * @example
 * convertLength(10, 'centimeter', 'inch');
 * convertLength(10, 'inch', 'centimeter');
 */
export const convertLength = configUnitConversion<LengthConversionUnit>('length')

/**
 * Converts a weight value from one unit to another.
 *
 * @param value The value to be converted.
 * @param fromUnit The unit of the input value.
 * @param toUnit The desired unit for the output value.
 * @param precision The precision to round to. Defaults 0
 * @returns The converted value.
 * @throws An error if the units are not supported.
 * 
 * @example
 * convertWeight(10, 'kilogram', 'pound');
 * convertWeight(10, 'pound', 'kilogram');
 */
export const convertWeight = configUnitConversion<WeightConversionUnit>('weight')
