import { AssessmentType, IMeasurement, MeasurementKey } from '../redux/assessment/assessment.types';
import { BASE_PATH, ROOT_PATH } from './PMotionGlobals';
import { BodyParts, SidePrefix } from '../redux/assessment/body-parts.types';
import { IOrg, OrgRole } from '../redux/org/org.types';
import { Redirect, Route, RouteProps } from "react-router-dom";
import { ReportTableType } from '../redux/report/report.types';
import { endsWith, startsWith, trim } from 'lodash';

import { IDType } from '../redux/core.types';
import { IUser } from '../redux/user/user.types';
import { LinkType } from '../components/breadcrumbs/breadcrumbs.component';
import React from "react";
import {intersection} from 'lodash';

type IRoutes = {
	HOME:string;
	HOME_MANAGMENT:string;
	LOGIN:string;
	RESET_PASSWORD:string;
	CHANGE_PASSWORD:string;
	JOIN:string;
	ASSESSMENT:string;
	PLAYER:string;
	PLAYER_ASSESSMENT_BODY:string;
	PLAYER_MANAGEMENT:string;
	EXERCISE:string;
	ACCOUNT:string;
	MESSAGES:string;
	TERMS:string;
	ADMIN:string;
	REPORTS:string;
	SUBSCRIPTION:string;
}

const addEdgeSlashes = (path:string, addEnd:boolean=true):string => {
	let newPath = trim(path);
	if( !startsWith(newPath, '/') ){
        newPath =  '/' + newPath;
    }
	if( addEnd && !endsWith(newPath, '/') ){
        newPath = newPath + '/';
	}
	return newPath;
}

const removeEdgeSlashes = (path:string, stripStart:boolean=false):string => {
	// remove end slash
	let newPath = trim(path);
	if( endsWith(path, '/') ){
        newPath = newPath.substring(0, newPath.length-1);
	}
	// remove start slash if needed
	if( stripStart && startsWith(path, '/') ){
        newPath = newPath.substring(1);
	}
	const parts = newPath.split('/');
	// remove file with extension
	if(parts.length && parts[parts.length-1].includes('.')){
		parts.pop();
	}
	return parts.join('/');
}

const getUrlBase = () => {
	const currentPath = addEdgeSlashes(window.location.pathname);
	const validBase = removeEdgeSlashes(BASE_PATH, true).length > 0;
	const validRoot = removeEdgeSlashes(ROOT_PATH, true).length > 0;
	return (validBase && currentPath.indexOf(addEdgeSlashes(BASE_PATH))===0) ? BASE_PATH : (validRoot && currentPath.indexOf(addEdgeSlashes(ROOT_PATH))===0) ? ROOT_PATH : '';
}

export const URL_BASE = getUrlBase();

export const LOGIN_URL_BASE =  `${URL_BASE}/login`;
export const ADMIN_URL_BASE =  `${URL_BASE}/admin`;
export const REPORTS_URL_BASE =  `${URL_BASE}/reports`;

export enum AdminSection {
	Organizations = 'organizations',
	Users = 'users',
	Assessments = 'assessments',
	Correctives = 'correctives',
	Messages = 'messages'
}

export enum ReportSection {
	Member = 'member'
}

export enum PlayerTab {
	Assessments = 'assessments'
}

export enum PlayerSubSection {
	Body = 'body'
}

const Routes:IRoutes = {
	HOME : `${URL_BASE}/`,
	HOME_MANAGMENT: `${URL_BASE}/player-management`,
	LOGIN : `${LOGIN_URL_BASE}/:section?`,
	RESET_PASSWORD : `${URL_BASE}/reset-password/:login`,
	CHANGE_PASSWORD : `${URL_BASE}/change-password`, 
	JOIN : `${URL_BASE}/join/:login`,
	ASSESSMENT : `${URL_BASE}/assessment/:assessmentType?`,
	PLAYER : `${URL_BASE}/player/:playerId/:tab?/:itemId?/:editMode?/:measurementId?`,
	PLAYER_ASSESSMENT_BODY : `${URL_BASE}/player/:playerId/${PlayerTab.Assessments}/:itemId/${PlayerSubSection.Body}/:bodyPart?/:measurementId?`,
	PLAYER_MANAGEMENT : `${URL_BASE}/player-management/:playerId/:bodyPart?/:measurementId?`,
	EXERCISE : `${URL_BASE}/exercise/:exerciseId`,
	ACCOUNT : `${URL_BASE}/account/:tab?`,
	MESSAGES : `${URL_BASE}/messages/:threadId?/:targetId?`,
	TERMS : `${URL_BASE}/terms`,
	ADMIN : `${ADMIN_URL_BASE}/:section?/:subSection?/:subSectionId?/:subSectionAction?`,
	REPORTS : `${REPORTS_URL_BASE}/:reportType/:playerId?/:tab?`,
	SUBSCRIPTION : `${URL_BASE}/subscription`
}

export const getOrgLoginPath = (orgName:string) => {
	return `${URL_BASE}/${orgName}`;
}

export type LoginRouteParams = { 
	section?: string;
};

export const getLoginPath = (section?:string) => {
	const suffix = section ? `/${section}` : '';
	return `${LOGIN_URL_BASE}${suffix}`;
};

export type AssessmentRouteParams = { 
	assessmentType?: string | AssessmentType;
};

export const getAssessmentPath = (assessmentType?:AssessmentType) => {
	let path = `${URL_BASE}/assessment`;
	if( assessmentType ){
		path += `/${assessmentType.toLowerCase()}`
	}
	return path;
}

export enum PlayerEditMode {
	Edit = 'edit'
}

export type PlayerRouteParams = { 
	playerId: string;
	tab?: PlayerTab;
	itemId?: string;
	editMode?: PlayerEditMode | PlayerSubSection;
	bodyPart?: string;
	measurementId?: MeasurementKey;
};

export type PlayerRouteBodyParams = { 
	playerId: string;
	itemId?: string;
	bodyPart?: string;
	measurementId?: MeasurementKey;
};

export type PlayerRouteState = {
	referrer?:LinkType;
}

export const getPlayerPath = (playerId:IDType, tab?:PlayerTab):string => {
	return `${URL_BASE}/player/${playerId}${tab ? '/'+tab : ''}`;
}

export const getPlayerAssessmentPath = (playerId:IDType, assessmentId:IDType | string, mode?:PlayerEditMode | PlayerSubSection):string => {
	let url = `${getPlayerPath(playerId, PlayerTab.Assessments)}/${assessmentId}`;
	if(mode){
		url = url + `/${mode}`
	}
	return url;
}

export type GetBodyRoutePathType = (playerId:IDType, bodyPart?:BodyParts, measurement?:IMeasurement | MeasurementKey, measurementSide?:SidePrefix) => string;

export const getPlayerAssessmentBodyPath = (playerId:IDType, assessmentId?:IDType | string, bodyPart?:BodyParts, measurement?:IMeasurement | MeasurementKey, measurementSide?:SidePrefix):string => {
	let url = getPlayerAssessmentPath(playerId, assessmentId!==undefined ? assessmentId : '', PlayerSubSection.Body);
	let suffix = '';
	if( bodyPart ){
		switch( bodyPart ){
			case BodyParts.Root :
			case BodyParts.Front :
				suffix = '';
				break;
			default :
				suffix = `/${bodyPart}`;
		}
	}
	if(measurement && suffix!==''){
		const isKey = typeof measurement ==='string';
		suffix += `/${measurementSide ? measurementSide : ''}${isKey ? (measurement as MeasurementKey) : (measurement as IMeasurement).key}`;
	}
	return `${url}${suffix}`;
}

export type PlayerManagementRouteParams = PlayerRouteBodyParams;

export type PlayerManagementRouteState = {
	referrer?:LinkType;
}

export const getPlayerManagementPath:GetBodyRoutePathType = (playerId:IDType, bodyPart?:BodyParts, measurement?:IMeasurement | MeasurementKey, measurementSide?:SidePrefix):string => {
	/*if( bodyPart && measurement ){
		bodyPart = checkForMeasurementUrlOverride(bodyPart, measurement);
	}*/
	let suffix = '';
	if( bodyPart ){
		switch( bodyPart ){
			case BodyParts.Root :
			case BodyParts.Front :
				suffix = '';
				break;
			default :
				suffix = `/${bodyPart}`;
		}
	}
	if(measurement && suffix!==''){
		const isKey = typeof measurement ==='string';
		suffix += `/${measurementSide ? measurementSide : ''}${isKey ? (measurement as MeasurementKey) : (measurement as IMeasurement).key}`;
	}
	return `${URL_BASE}/player-management/${playerId}${suffix}`;
}

export type ExerciseRouteParams = { 
	exerciseId: string;
};

export const getExercisePath = (exerciseId:IDType):string => {
	return `${URL_BASE}/exercise/${exerciseId}`;
}

export type AccountRouteParams = { 
	tab?: string;
};

export const getAccountPath = (tabId?:string):string => {
	const base = `${URL_BASE}/account`;
	const suffix = tabId ? `/${tabId}` : ''
	return base + suffix;
}

export enum MessageThreadType {
	New = 'new'
}

export type MessageThreadRouteParams = { 
	threadId?: string | MessageThreadType;
	targetId?: string;
};

type threadType = IDType | MessageThreadType;
export const getMessagesPath = (threadId?:threadType, targetId?:IDType):string => {
	const base = `${URL_BASE}/messages`;
	let suffix = (threadId!==undefined) ? `/${threadId}` : '';
	suffix = (threadId!==undefined && targetId!==undefined) ? `${suffix}/${targetId}` : suffix;
	return base + suffix;
}

export type ResetPasswordRouteParams = { 
	login: string;
};

export const getResetPasswordPath = (userName:string) => {
	return `${URL_BASE}/reset-password/${encodeURIComponent(userName)}`;
}

export type JoinRouteParams = { 
	login: string;
};

export type AdminRouteParams = { 
	section?:AdminSection;
	subSection?:string;
};

export const getAdminPath = (section:AdminSection=AdminSection.Organizations, subSection?:string) => {
	let suffix = section ? `/${section}` : '';
	suffix = (section && subSection) ? `${suffix}/${subSection}` : suffix;
	return `${ADMIN_URL_BASE}${suffix}`;
};

export enum AdminUserRouteMode {
	Add = 'add'
}
export type AdminUserRouteParams = {
	userId:string | AdminUserRouteMode;
}
export type AdminUserRouteState = {
	role:OrgRole;
}

export const AdminUserRoutes = {
	EDIT : `${getAdminPath(AdminSection.Users)}/:userId`
}

export const getAdminUserPath = (userId:IDType | AdminUserRouteMode) => {
	return getAdminPath(AdminSection.Users, `${userId}`);
};

export type AdminOrgRouteParams = {
	orgId:string;
}

export const AdminOrgRoutes = {
	EDIT : `${getAdminPath(AdminSection.Organizations)}/:orgId`
}

export const getAdminOrgPath = (orgId:IDType) => {
	return getAdminPath(AdminSection.Organizations, `${orgId}`);
};

type ReportSectionType = ReportSection | ReportTableType;
export type ReportsRouteParams<T = ReportSectionType>  = { 
	reportType:T;
	playerId?: string;
};

export const ReportsRoutes = {
	Table : `${REPORTS_URL_BASE}/:reportType`,
	Member : `${REPORTS_URL_BASE}/member/:playerId/:tab?`
}

export type ReportsMemberRouteParams = {
	playerId:string;
	tab?:ReportTableType;
}

export type ReportsMemberRouteState = {
	fromReportTableType:ReportTableType;
	referrer?:LinkType;
}

export const getReportsPath = (reportType:ReportSectionType=ReportTableType.Compliance, playerId?:IDType, tab?:ReportTableType) => {
	return `${REPORTS_URL_BASE}/${reportType}${playerId!==undefined ? '/'+playerId : ''}${playerId!==undefined && tab!==undefined ? '/'+tab : ''}`;
};

export const getSubscriptionPath = () => {
	return `${URL_BASE}/subscription`;
};

export interface IFromState {
	label:string;
	url:string;
}


// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
type ChildrenType = Pick<React.HTMLProps<HTMLElement>, "children">;
type PrivateRouteProps = RouteProps & ChildrenType & {
	isAuthenticated: boolean;
	redirectPath?:string;
};
export const PrivateRoute: React.FC<PrivateRouteProps> = ({
	children,
	isAuthenticated,
	render,
	component,
	redirectPath=LOGIN_URL_BASE,
	...rest
}) => {
	return isAuthenticated ? (
		<Route {...rest} render={render} component={component}>{children}</Route>
	) : (
		<Route {...rest}
			render={props =>
				<Redirect to={{ pathname: redirectPath, state: { from: props.location } }} />
			}
		/>
	)
};

/* Will convert realtive paths to absolute ones using the global basePath in the PMotion object.*/
export const getAssetPath = (path:string):string => {
	/*const tests = ['/', './'];
	for(let i=0;i<tests.length;i++){
		const test = tests[i];
		if( path.indexOf(test)===0 ){
			return path.replace(test, `${BASE_PATH}/`);
		}
	}*/
	return path;
}

export const canAccess = (user:IUser, currentOrg:IOrg, allowedRoles:OrgRole[]) => {
	if(user.orgRoles.hasOwnProperty(currentOrg.key)){
		const roles = user.orgRoles[currentOrg.key];
		return !!intersection(roles, allowedRoles).length;
	}
	return false;
}

export default Routes;
