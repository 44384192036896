import { ProfileItem, ProfileItemType } from '../redux/player/player.types';
import { deburr, memoize } from 'lodash';

import { isPositiveNumber } from './validator';
import { MeasurementUnit, TFunction } from '../localization/localizationContext';
import { convertLength, convertWeight } from './convert-units';

export const trimInputFloat = (inputValue:string):string => {
	// remove non-numerical characters
	let parts = inputValue.replace(/[^\d.-]/g, '').split('-');
	let parsedInput = (parts.length > 1) ? ('-'+parts[1]) : parts[0];
	parsedInput = (parts.length > 1) ? ('-'+parts[1]) : parts[0];
	parts = parsedInput.split('.');
	// validate decimal by rebuilding and only allowing one decimal place
	parsedInput = parts[0] + ((parts.length > 1) ? ('.' + parts[1].substring(0, 1)) : '');
	return parsedInput;
}

export const trimInputInteger = (inputValue:string):string => {
	// remove non-numerical characters
	return inputValue.replace(/(-?)([^0-9]*)/g, '$1');
}

export const trimInputNonNegativeInteger = (inputValue:string, maxDigits?:number):string => {
	// remove non-numerical characters
	let parsedInput = inputValue.replace(/[^0-9]*/g, '');
	if(maxDigits!==undefined && maxDigits > 0){
		// cut off anything passed max digits
		parsedInput = parsedInput.substring(0, maxDigits);
	}
	return parsedInput;
}

export const addClass = (element:Element, className:string) => {
	const isSvg = (element instanceof SVGElement);
	const classes:string = isSvg ? (element as SVGElement).className.baseVal : element.className;
	const arr = classes.split(' ');
	
	if (arr.indexOf(className) === -1) {
		if( isSvg ){
			(element as SVGElement).setAttribute('class', classes + ' ' + className);
		} else {
			element.className += ' ' + className;
		}
	}
}

export const removeClass = (element:Element, className:string) => {
	const separator = ' ';
	const isSvg = (element instanceof SVGElement);
	const classes:string = isSvg ? (element as SVGElement).className.baseVal : element.className;
	const newClasses = classes.split(separator).filter(cls => cls!==className).join(separator);
	if( isSvg ){
		(element as SVGElement).setAttribute('class', newClasses);
	} else {
		element.className = newClasses;
	}
}

export const toggleClass = (element:Element, className:string, bool:boolean) => {
	bool ? addClass(element, className) : removeClass(element, className);
}

export const roundTo = (num:number, decimalPlaces:number=2) => {
	const multiplier = Math.pow(10, Math.max(decimalPlaces, 0));
	if( multiplier > 0 ){
		return Math.round(num * multiplier) / multiplier;
	}
	return Math.round(num);
}

export const roundToString = (num:number, decimalPlaces:number=2):string => {
	return roundTo(num, decimalPlaces).toFixed(decimalPlaces);
}

/* Round to nearest increment */
export const roundToIncrement = (num:number, increment:number, offset:number=0) => {
	return Math.ceil((num - offset) / increment ) * increment + offset;
}

export const displayHeight = (inches:number, measurementUnit: MeasurementUnit) => {
	if (measurementUnit === MeasurementUnit.Metric) {
		return `${convertLength(inches, 'inch', 'centimeter')} cm`
	} else {
		const feet = Math.floor(inches / 12);
		inches %= 12;
		const prefix = (feet > 0) ? `${feet}'` : '';
		return `${prefix}${inches}"`;
	}
}

export const displayWeight = (pounds:number, measurementUnit: MeasurementUnit) => {
	if (measurementUnit === MeasurementUnit.Metric) {
		return `${convertWeight(pounds, 'pound', 'kilogram')} kg`
	} else {
		return `${pounds} lbs`;
	}
}

export const displayProfileItem = ({key, value}:ProfileItem, t: TFunction, measurementUnit: MeasurementUnit, allowUndefined?:boolean):[string, string | undefined] | undefined => {
	type numType = number | null | undefined;
	const hasValue = value!==null && value!==undefined;
	if(hasValue || allowUndefined){
		switch( key ){
			case ProfileItemType.BattingSide :
				return [t('user_profile_item', ProfileItemType.BattingSide), hasValue ? t('common.option', value as string) : undefined];
			case ProfileItemType.ThrowingSide :
				return [t('user_profile_item', ProfileItemType.ThrowingSide), hasValue ? t('common.option', value as string) : undefined];
			case ProfileItemType.LegSide :
				return [t('user_profile_item', ProfileItemType.LegSide), hasValue ? t('common.option', value as string) : undefined];
			case ProfileItemType.ShootingSide :
				return [t('user_profile_item', ProfileItemType.ShootingSide), hasValue ? t('common.option', value as string) : undefined];
			case ProfileItemType.SwingSide :
				return [t('user_profile_item', ProfileItemType.SwingSide), hasValue ? t('common.option', value as string) : undefined];
			case ProfileItemType.GeneralStrongSide :
				return [t('user_profile_item', ProfileItemType.GeneralStrongSide), hasValue ? t('common.option', value as string) : undefined];
			case ProfileItemType.Height :
				value = (hasValue && value && typeof value==='string') ? parseInt(value) : value;
				const isHeightPos = hasValue ? isPositiveNumber(value as numType) : undefined;
				return isHeightPos || allowUndefined ? [t('user_profile_item', ProfileItemType.Height), isHeightPos ? displayHeight(value as number, measurementUnit) : undefined] : undefined;
			case ProfileItemType.Weight :
				value = (hasValue && value && typeof value==='string') ? parseInt(value) : value;
				const isWeightPos = hasValue ? isPositiveNumber(value as numType) : undefined;
				return isWeightPos || allowUndefined ? [t('user_profile_item', ProfileItemType.Weight), isWeightPos ? displayWeight(value as number, measurementUnit) : undefined] : undefined;
			default :
				if(allowUndefined){
					return [key, (value && typeof value==='string') ? value : undefined];
				}
		}
	}
	return undefined;
}

// Good for removing stripping latin characters and lowercasing for searches
export const stringDeburred = memoize((input:string) => deburr(input).toLowerCase());