import { ConnectedProps, connect } from "react-redux";
import React, { useMemo } from "react";

import { IOrg } from './redux/org/org.types';
import { IRootState } from "./redux/root.types";
import RootContainer from "./RootContainer";
import {
	ThemeProvider,
} from "@material-ui/core/styles";
import { checkUserSession } from "./redux/user/user.actions";
import { createStructuredSelector } from "reselect";
import generateAppTheme from './themes/App.theme';
import { selectOrgInfo } from './redux/org/org.selectors';
import { setDashboardState } from './redux/dashboard/dashboard.actions';
import useDocumentSizer from './hooks/useDocumentSizer.hook';
import useOnce from './hooks/useOnce.hook';
import { INITIAL_DASHBOARD_STATE } from './redux/dashboard/dashboard.reducer';
import { LocalizationProvider } from "./localization/localizationContext";

/*if (process.env.NODE_ENV !== 'production') {
	const whyDidYouRender = require('@welldone-software/why-did-you-render');
	whyDidYouRender(React, {
		include: [/^ConnectFunction$/, /^AudioPlayer$/, /^PlayerCorrectivesProgram$/, /^PlayerPage$/],
		trackAllPureComponents: true,
		//include: [/^ConnectFunction$/]
	});
}*/

interface mapStateToPropsInterface {
	org?:IOrg;
}
const mapStateToProps = createStructuredSelector<
	IRootState,
	mapStateToPropsInterface
>({
	org: selectOrgInfo
});

const mapDispatchToProps = {
	checkUserSession,
	setDashboardState
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type AppProps = PropsFromRedux;

export const App: React.FC<AppProps> = ({ checkUserSession, org, setDashboardState }) => {

	useDocumentSizer();

	useOnce(() => {
		checkUserSession();
		const {rowsPerPage, ...otherDefaults} = INITIAL_DASHBOARD_STATE;
		setDashboardState(otherDefaults); // partial reset on reload
		return true;
	}, [checkUserSession, setDashboardState]);

	const theme = useMemo(() => generateAppTheme(org), [org])

	return (
		<ThemeProvider theme={theme}>
			<LocalizationProvider>
				<RootContainer />
			</LocalizationProvider>
		</ThemeProvider>
	);
};

export default connector(App);