import React, { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { get } from 'lodash';
import SpinnerBackdrop from '../components/spinner-backdrop/spinner-backdrop.component';

type LanguageCodeType = 'en' | 'it' // add supported language codes here

type CountryCodeType = 'US' | 'IT'  // add supported country codes here

type LanguageStrings = {
  [key: string]: string | LanguageStrings;
};

interface LocalizationContextType {
  countryCode: CountryCodeType;
  measurementUnit: MeasurementUnit;
  t: TFunction;
  t_media: (source: string) => string;
}

const LocalizationContext = createContext<LocalizationContextType | null>(null);

export enum MeasurementUnit {
  Metric = 'METRIC',
  Imperial = 'IMPERIAL'
}

export type TFunction = (token: string, variable?: string) => string;

const normalizeKey = (variable: string): string => {
  // Convert the string to lowercase
  return variable
    .toLowerCase()                          // Convert all characters to lowercase
    .replace(/[^a-z0-9\s._]/g, '')           // Remove any character that is not a letter, number, space, dot or underscore
    .replace(/[.\s]+/g, '_');               // Replace spaces and dots with underscores
};

const getNestedValue = (obj: any, path: string): any => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

export const LocalizationProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [languageStrings, setLanguageStrings] = useState<LanguageStrings>({});

  const language = navigator.language || 'en-US'; // defaults to 'en-US'
  const [languageCode, countryCode] = language.split('-') as [LanguageCodeType, CountryCodeType];  // Get locale (e.g., 'en-US', 'it-IT')
  const measurementUnit = countryCode === 'US' ? MeasurementUnit.Imperial : MeasurementUnit.Metric
  const isLoading = !Object.keys(languageStrings).length

  useEffect(() => {
    const loadLanguage = async (code: LanguageCodeType) => {
      let filePath: string;
      switch (code) {
        case 'it':
          filePath = '/locales/it/localization.json'
          break;
        default:
          filePath = '/locales/en/localization.json'
          break;
      }
      const response = await fetch(filePath)
      const data = await response.json()

      setLanguageStrings(data)
    }

    loadLanguage(languageCode)
  }, [languageCode])

  const t_media = useCallback((source: string): string => {
    switch (languageCode) {
      case 'it':
        return source.replace(/pmotiontech\.com\/media/g, 'pmotiontech.com/it/media');
      default:
        return source;
    }
  }, [languageCode])

  const t = useCallback((token: string, variable?: string): string => {
    if (variable) {
      var v_key = normalizeKey(variable)
      const tokenPath = token + "." + v_key
      return `${getNestedValue(languageStrings, tokenPath.toLowerCase()) || variable}`;
    }

    return `${get(languageStrings, token.toLowerCase(), token)}`;
  }, [languageStrings]);

  if (isLoading) {
    return <SpinnerBackdrop isLoading={true} />
  }

  return (
    <LocalizationContext.Provider value={{
      countryCode,
      measurementUnit,
      t,
      t_media
    }}>
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalization = (): LocalizationContextType => {
  const ctx = useContext(LocalizationContext);
  if (!ctx) throw new Error('useLocalization must be used within LocalizationProvider');
  return ctx;
};
